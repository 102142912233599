import { BlueButton } from '@hn-ui/buttons';
import { ClasslinkSquare } from '@hn-ui/icons';
import { styled } from 'styled-components';

export interface IClasslinkButtonProps {
  text: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ScClasslinkSquare = styled(ClasslinkSquare)`
  width: 32px;
  height: 32px;

  @media (max-width: 640px) {
    width: 28px;
    height: 28px;
  }
`;

const Button = styled(BlueButton)`
  justify-content: flex-start;
  background: ${({ theme }) => theme.colors.black100};
  color: #0a4d7f;
  border: 2px solid #0a4d7f;
  padding: 6px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: rgba(66, 116, 246, 0.1);
    }
  }

  @media (max-width: 640px) {
    padding: 4px 6px;
    font-size: 14px;
  }
`;

const ButtonText = styled.p`
  flex: 1;
`;

const ClasslinkButton = ({ text, onClick, ...rest }: IClasslinkButtonProps) => {
  return (
    <Button onClick={onClick} LeftIcon={ScClasslinkSquare} {...rest}>
      <ButtonText>{text}</ButtonText>
    </Button>
  );
};

export { ClasslinkButton };
